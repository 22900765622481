import React, { Fragment } from 'react';
import { Form, Col, Row, Alert } from 'react-bootstrap'

export const formDataTypes = {
    text: 'text',
    textArea: 'textarea',
    email: 'email',
    checkbox: 'checkbox',
    headLabel: 'header'
}

export const FormRequirementAlert = () => (
    <Alert variant={'warning'} style={{ border: 'solid 2px #ffd324' }}>
        All fields marked * are required fields and must be completed.
    </Alert>
)

export const FormDataGeneric = ({ label, keyName, type, required, ...rest }) => {
    return (
        <Form.Group as={Row} className="mb-3" >
            <Form.Label htmlFor={keyName} column sm="2">{label} {required ? <span style={{ color: 'red' }}>*</span> : <Fragment />}</Form.Label>
            <Col sm="10">
                <Form.Control name={keyName} id={keyName} type={type} required={required} {...rest} />
            </Col>
        </Form.Group>
    )
}

export const FormDataCheckBoxes = ({ label, keyName, type, boxes = [{ label: '', keyName: '' }] }) => {
    return (
        <Form.Group as={Row} className="mb-3" >
            <Form.Label>{label}</Form.Label>
            {
                boxes.map((b, k) => (
                    <Col sm={6} key={k} >
                        <div className="mb-3">
                            <Form.Check type={type} id={`check-${keyName}-${k.keyName}`}>
                                <Form.Check.Label htmlFor={b.keyName} name={b.keyName}>{b.label}</Form.Check.Label>
                                <Form.Check.Input type={type} id={b.keyName} name={b.keyName}/>
                            </Form.Check>
                        </div>
                    </Col>
                ))
            }
        </Form.Group>
    )
}

export const FormDataHeadLabel = ({ label }) => {
    return <h2 style={{ fontWeight: 700 }}>{label}</h2>
}

const GetFormComponent = (f, k) => {
    switch (f.type) {
        case formDataTypes.textArea: return <FormDataGeneric {...f} key={k} />
        case formDataTypes.text: return <FormDataGeneric {...f} key={k} />
        case formDataTypes.email: return <FormDataGeneric {...f} key={k} />
        case formDataTypes.checkbox: return <FormDataCheckBoxes {...f} key={k} />
        case formDataTypes.headLabel: return <FormDataHeadLabel {...f} key={k} />
        default: return <Fragment />
    }
}

export const FormBuilder = ({ form = [] }) => form.map((f, k) => GetFormComponent(f, k))

export const OnCompleteMessage = () => <Col style={{paddingBottom: 20}}><Alert variant='success' style={{ width: 500, margin: 'auto'}}><h5 style={{ fontWeight: 700, textAlign: 'center' }}>Thank you for submitting the form, someone will be in touch as soon as possible.</h5></Alert></Col>;
export const OnFailMessage = () => <Col style={{paddingBottom: 20}}><Alert variant='error' style={{ width: 500, margin: 'auto'}}><h5 style={{ fontWeight: 700, textAlign: 'center' }}>Something went wrong, Please send your enquiry to <a href="mailto:greg@pumpapps.com.au" >greg@pumpapps.com.au</a></h5></Alert></Col>;
