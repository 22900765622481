import React, { useState, useCallback, Fragment } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { formDataTypes, OnCompleteMessage, OnFailMessage } from '../components/formbuilder';

export const FormPage = (props) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
            <FormPageContent {...props} />
        </GoogleReCaptchaProvider>
    )
}

const bodyString = (label, value) => `<span>${label}: ${value}</span><br>`;

const CreateFetchBody = (formInput, e, defaultbody = '') => {
    let body = defaultbody;
    for (let i = 0; i < formInput.length; i++) {
        switch (formInput[i].type) {
            case formDataTypes.headLabel: body += `<h4>${formInput[i].label}</h4>`; break;
            case formDataTypes.checkbox: body += CreateFetchBody(formInput[i].boxes, e); break;
            case undefined: body += bodyString(formInput[i].label, e.target[formInput[i].keyName].checked); break;
            default: body += bodyString(formInput[i].label, e.target[formInput[i].keyName]?.value); break;
        }
    }

    return body;
}

export const FormPageContent = ({ formComponent, formInput, formTitle }) => {
    const [isComplete, setIsComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [failed, setFailed] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSumitForm = useCallback(
        (e) => {
            setIsLoading(true);

            e.preventDefault();
            if (e.currentTarget.checkValidity() === false) {
                e.stopPropagation();
                setIsLoading(false);
                return;
            }

            if (!executeRecaptcha) {
                //console.log("Execute recaptcha not yet available");
                setIsLoading(false);
                return;
            }

            executeRecaptcha("formSubmit").then((gReCaptchaToken) => {
                //console.log(gReCaptchaToken, "response Google reCaptcha server");
                submitEnquiryForm(gReCaptchaToken, CreateFetchBody(formInput, e, `<h2>${formTitle}</h2>`), setIsComplete, e?.target?.email?.value);
            });
        },
        [executeRecaptcha, formInput, formTitle]
    );

    const submitEnquiryForm = (gReCaptchaToken, content, setIsComplete, sentByEmail) => {
        try {
            fetch("/api/contact", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    content: content,
                    gRecaptchaToken: gReCaptchaToken,
                    sentByEmail: sentByEmail
                }),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res?.success === true) {
                        setIsComplete(true);
                    } else {
                        setIsComplete(false);
                        setFailed(true);
                    }
                    setIsLoading(false);
                    setFailed(true);
                });
        } catch (err) {
            //console.log(err);
            setIsLoading(false);
        }
    };
    if (failed) return <Fragment><OnFailMessage /></Fragment>
    if (isComplete) return <Fragment><OnCompleteMessage /></Fragment>
    return (
        <Fragment>
            <Form onSubmit={handleSumitForm}>
                {formComponent}
                {
                    isLoading ? <Alert variant={'primary'}><h3><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner> ...Sending Request...</h3></Alert> : <Fragment />
                }
                <Button disabled={isLoading} type="submit" id={'formSubmit'} variant='primary' style={{ cursor: 'pointer' }}>Submit</Button>
            </Form>
        </Fragment>
    )
}